import { FC } from 'react';

import { TableCell } from '@mui/material';

import { CompareIcon } from 'shared/assets/icons';
import { TCompare } from 'shared/services/types/compare.types';
import { Status } from 'shared/ui/status';
import { CustomTooltip } from 'shared/ui/tooltip';
import { getTransformedDate } from 'shared/utils';

interface IProps {
  row: TCompare.ResponseItem;
  matchTransaction: (id: number) => void;
  isMatchHidden?: boolean;
}

export const TransComparePart: FC<IProps> = ({ row, matchTransaction, isMatchHidden }) => {
  const isStatusMath = ['received', 'scam'].includes(row.application_status || '');

  return row.transaction_status === 'declined' ? (
    <>
      <TableCell align='left'>
        <Status status={'declined'} />
      </TableCell>
      <TableCell colSpan={3} align='left'>
        Application not found
      </TableCell>
      <TableCell
        align='center'
        sx={{
          position: 'relative',
          '&:after': {
            content: `''`,
            display: 'block',
            width: '1px',
            height: '100%',
            background: '#BDC1D2',
            position: 'absolute',
            top: '0',
            left: '50%'
          }
        }}
      />
    </>
  ) : row.application_timestamp === null ? (
    <>
      <TableCell align='left'>
        <Status status={'in_progress'} />
      </TableCell>
      <TableCell colSpan={3} align='left'>
        Waiting for application
      </TableCell>
      <TableCell
        align='center'
        sx={{
          position: 'relative',
          '&:after': {
            content: `''`,
            display: 'block',
            width: '1px',
            height: '100%',
            background: '#BDC1D2',
            position: 'absolute',
            top: '0',
            left: '50%'
          }
        }}
      />
    </>
  ) : (
    <>
      <TableCell align='left'>
        <Status status={row.application_status} />
      </TableCell>
      <TableCell align='left'>{row.application_transaction_id}</TableCell>
      <TableCell align='left'>
        {getTransformedDate(row?.application_timestamp, 'dd/MM/yy HH:mm:SS')}
      </TableCell>
      <TableCell align='right' sx={{ cursor: 'pointer' }}>
        {[row.transaction_id, row.application_id].includes(null) &&
          isMatchHidden &&
          isStatusMath && (
            <CustomTooltip title='Match'>
              <CompareIcon
                onClick={() => {
                  matchTransaction(row.application_id ? row.application_id : -1);
                }}
              />
            </CustomTooltip>
          )}
      </TableCell>
      <TableCell
        align='center'
        sx={{
          position: 'relative',
          '&:after': {
            content: `''`,
            display: 'block',
            width: '1px',
            height: '100%',
            background: '#BDC1D2',
            position: 'absolute',
            top: '0',
            left: '50%'
          }
        }}
      />
    </>
  );
};
