import { FC, useEffect, useMemo, useState } from 'react';

import { Box, Button, Grid } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';

import { useActionUsers } from 'features/users/api';

import { REGEX, ROLE_USER } from 'shared/constants';
import { useUser } from 'shared/hooks';
import { TMethods } from 'shared/services/types/methods.types';
import { TUsers } from 'shared/services/types/users.types';
import { FormWrapper } from 'shared/ui/form-wrapper';
import { Input } from 'shared/ui/input';
import { ModalTemplate } from 'shared/ui/modal-template';
import { SelectSearch } from 'shared/ui/select-multi';
import { addMessageToast, hasFormValuesChanged } from 'shared/utils';

interface IProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  userData: TUsers.UserData | null;
  methodsData?: TMethods.MethodItem[];
  onClose: () => void;
  methodID?: number;
  technicalMethodLabel?: string;
  technicalMethodSource?: string;
  setIsOpenAddAccount: React.Dispatch<React.SetStateAction<boolean>>;
  setUserID: React.Dispatch<React.SetStateAction<number | null>>;
  setUserMethodID?: React.Dispatch<React.SetStateAction<number | null>>;
}

export const AddUserModal: FC<IProps> = ({
  isOpen,
  setIsOpen,
  methodsData,
  userData,
  onClose,
  methodID,
  setIsOpenAddAccount,
  setUserID,
  setUserMethodID
}) => {
  const [isPassForgot, setIsPassForgot] = useState(!!userData);
  const [isCreateAccount, setIsCreateAccount] = useState(false);
  const { rolesAccess } = useUser();
  const isManager = rolesAccess(['Менеджер']);

  const methods = useForm({ mode: 'onBlur' });
  const { reset } = methods;

  useEffect(() => {
    setIsCreateAccount(['Агент', 'Приложение'].includes(methods.watch('role')));
  }, [methods.watch()]);

  const isConfirmationNeeded = hasFormValuesChanged(methods.watch(), userData ?? {}, ['method_id']);

  const methodData = methodsData?.find(method => method.id === methodID);
  const methodName = methodData?.name;

  useEffect(() => {
    setIsPassForgot(!!userData);
    if (userData && isOpen) {
      reset(userData);
    } else {
      reset({});
    }
  }, [userData, isOpen]);

  const { create, update } = useActionUsers();

  const onSubmit: SubmitHandler<TUsers.CreateUser> = async data => {
    try {
      if (!userData) {
        const { data: user } = await create.mutateAsync({ method_id: methodID, ...data });
        addMessageToast('User created', 'success');
        if (['Агент', 'Приложение'].includes(user.role)) {
          setUserID(user.id);
          setUserMethodID?.(user.method_id);
        } else {
          reset();
        }

        if (isCreateAccount) {
          setIsOpenAddAccount(true);
        }
      } else {
        //@ts-ignore
        const { methods, ...updateData } = data;
        await update.mutateAsync({ ...updateData, user_id: userData.id });
        addMessageToast('User updated', 'success');
      }
      onClose();
      reset([]);
    } catch (error) {
      addMessageToast(error);
    }
  };

  const userRoleOptions = useMemo(() => {
    const options = ROLE_USER.map(item => {
      return { label: item.value, value: item.label };
    });

    const filterOptions = options?.filter(item => {
      if (isManager && ['Admin', 'Manager'].includes(item.label)) {
        return false;
      }
      return true;
    });

    return filterOptions;
  }, [ROLE_USER, isManager]);

  const methodsOptions = useMemo(() => {
    return methodsData?.map(item => ({ label: item.name, value: item.id })) || [];
  }, [methodsData]);

  return (
    <ModalTemplate
      isConfirmationNeeded={isConfirmationNeeded}
      close={() => {
        onClose();
      }}
      isOpen={isOpen}
      titleText={userData ? 'Edit user' : 'Add user'}
      hideBackdrop={false}
      reset={() => {
        reset();
        methods.clearErrors();
      }}
      maxWidth={413}>
      <FormWrapper onSubmit={onSubmit} methods={methods}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <Grid
            container
            direction={'column'}
            columnGap={'20px'}
            rowGap={15}
            wrap={'nowrap'}
            gridTemplateColumns={'1fr '}
            display='grid'
            gridTemplateRows='auto auto 1fr'>
            {methodData === undefined && (
              <Grid item>
                <SelectSearch
                  methods={methods}
                  inputPlaceholder='Text'
                  name='method_id'
                  inputLabel='Method*'
                  rules={{
                    required: 'Please, choose a method'
                  }}
                  options={methodsOptions}
                />
              </Grid>
            )}
            <Grid item>
              <Input
                methods={methods}
                placeholder='Text'
                label='Email*'
                name='email'
                rules={{
                  required: 'Please, enter email',
                  pattern: {
                    value: REGEX.EMAIL,
                    message: 'Enter the correct email'
                  }
                }}
              />
            </Grid>
            <Grid item>
              <Input
                methods={methods}
                placeholder='Text'
                label='Name*'
                name='name'
                rules={{ required: 'Please, enter name' }}
              />
            </Grid>
            <Grid
              key={isPassForgot.toString()}
              item
              sx={{
                order: userData ? '100' : '0',
                marginTop: isPassForgot ? '-8px' : '0',
                position: 'relative'
              }}>
              {isPassForgot ? (
                <Button
                  variant='text'
                  onClick={() => setIsPassForgot(false)}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'end',
                    fontSize: '16px',
                    fontWeight: '500',
                    padding: 0
                  }}>
                  Forgot password?
                </Button>
              ) : (
                <Input
                  methods={methods}
                  placeholder='Text'
                  label={userData ? 'New password*' : 'Password*'}
                  name='password'
                  isPass
                  rules={{
                    required: 'Please, enter password',
                    minLength: { message: 'Minimum length - 10', value: 10 }
                  }}
                />
              )}
            </Grid>

            <Grid item>
              <SelectSearch
                methods={methods}
                name='role'
                inputPlaceholder='Text'
                inputLabel='Role*'
                rules={{
                  required: 'Please, choose a role'
                }}
                options={userRoleOptions}
              />
            </Grid>
          </Grid>
        </Box>
        <Box
          mt={'30px'}
          display={'flex'}
          gap={'16px'}
          alignItems={'center'}
          justifyContent={'center'}>
          <Button
            type='submit'
            fullWidth
            variant='contained'
            sx={{
              m: 0,
              borderRadius: 50,
              textTransform: 'none',
              maxWidth: isCreateAccount ? 175 : 'none',
              height: 40,
              boxShadow: 'none'
            }}>
            Save
          </Button>
          {isCreateAccount && !userData && (
            <Button
              type='submit'
              fullWidth
              variant='contained'
              sx={{
                m: 0,
                borderRadius: 50,
                textTransform: 'none',
                maxWidth: 175,
                height: 40,
                boxShadow: 'none'
              }}>
              Create account
            </Button>
          )}
        </Box>
      </FormWrapper>
    </ModalTemplate>
  );
};
