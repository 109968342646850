import { useMutation, useQueryClient } from '@tanstack/react-query';

import { TAccounts } from 'shared/services/types/accounts.types';
import { UsersService } from 'shared/services/users.service';

export const useCreateAccount = () => {
  const queryClient = useQueryClient();

  const create = useMutation({
    mutationFn: (data: TAccounts.CreateAccount) => UsersService.createAccount(data),
    mutationKey: ['create account'],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['get accounts'] });
      queryClient.invalidateQueries({ queryKey: ['get user accounts'] });
    }
  });

  const update = useMutation({
    mutationFn: (data: TAccounts.UpdateAccount) => UsersService.updateAccount(data),
    mutationKey: ['update account'],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['get accounts'] });
      queryClient.invalidateQueries({ queryKey: ['get user accounts'] });
    }
  });

  return { create, update };
};
